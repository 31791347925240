<template>
    <h1>Super Usuario</h1>
</template>

<script>
export default {
    name: 'PanelSuper',
    title: "Panel del Super Usuario"
}
</script>
