import Constants from "../../../scripts/constants"
import Session from "../../../scripts/Session"

let menu = []

if(Session.getRole() == Constants.Role.Super){
  menu = [
    {
      name: 'Servicios',
      to: '/Root/Service',
      icon: 'calendar'
    },
    {
      name: 'Usuarios',
      to: '/Root/User',
      icon: 'user'
    }
  ];
}else{
  menu = [
    {
      name: 'Calendario',
      to: '/',
      icon: 'calendar'
    },
    {
      name: 'Nueva solicitud',
      icon: 'plus',
      to: '/Service/Add'
    },
    {
      name: 'Servicios',
      heading: true
    },
    {
      name: 'Pendientes',
      icon: 'layers',
      to: '/Service/Send'
    },
    {
      name: 'Aceptados',
      icon: 'puzzle',
      to: '/Service/Accept'
    },
    {
      name: 'Activos',
      icon: 'energy',
      to: '/Service/Active'
    },
    {
      name: 'Configuración',
      heading: true
    },
    {
      name: 'Empleados',
      icon: 'user-following',
      to: '/Config/Employee'
    }
  ]
}



export default {
    'main': menu
}