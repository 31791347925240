<template>
  <h1>Servicios de las empresas</h1>
</template>

<script>
export default {
    title: "Lista de servicios"
}
</script>

<style>

</style>