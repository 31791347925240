<template>
  <service-layout v-on:exit="Home"></service-layout>
</template>

<script>
import TemplateServiceComponent from './templateServiceComponent'
export default {
  title: "Solicitar servicio",
  components:{
    'service-layout': TemplateServiceComponent
  },
  methods: {
    Home(){
      this.$router.push("/")
    }
  }
}
</script>