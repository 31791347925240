<template>
  <div>
    <LoadingBar v-if="loading" :minSpinner="false" label="Cargando servicios" />
    <!-- Page Content -->
    <div class="content">
      <h2 class="content-heading">Calendario de servicios
      </h2>
      <base-block rounded content-full>
        <b-row class="items-push">
          <b-col md="12">
            <!-- Full Calendar Component -->
            <full-calendar
              ref="fullCalendar"
              :options="calendarOptions"
            ></full-calendar>
            <!-- END Full Calendar Component -->
          </b-col>
        </b-row>
      </base-block>
    </div>
    <!-- END Page Content -->
  </div>
</template>

<style lang="scss">
// FullCalendar Custom overrides
@import './src/assets/scss/vendor/fullcalendar';
</style>

<script>
// FullCalendar, for more info and examples you can check out https://fullcalendar.io/
import FullCalendar from '@fullcalendar/vue'
import esLocale from "@fullcalendar/core/locales/es";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import bootstrapPlugin from '@fullcalendar/bootstrap'
import LoadingBar from '../loader/circle.vue'
import Tools from '../../scripts/tools';
import Urls from '../../scripts/urls';

export default {
  title: "Calendario",
  components: {
    FullCalendar,
    LoadingBar
  },
  data () {
    return {
      loading: false,
      calendarOptions: {
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          right: 'prev,next today dayGridMonth,timeGridWeek,listWeek'
        },
        locale: esLocale,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          listPlugin,
          bootstrapPlugin
        ],
        weekends: true,
        events: [],
        themeSystem: 'bootstrap',
        editable: true,
        dropabled: false,
        firstDay: 0
      }
    }
  },
  created () {
    let self = this
    Tools.VinderFetch({
        url: Urls.API.Service.List,
        data:{
            status: 'Pendient'
        },
        recived: _dat => {
          _dat.map(o => {
            self.calendarOptions.events.push({
              id: o.Id,
              title: o.TypeService,
              start: o.Date + " " + o.Hour,
              end: o.Date + " " + o.Hour,
              color: self.GetColor(o.Status),
              data: o
            })
          })
        },
        complete: () => self.loading = false
    })
  },
  methods: {
    GetColor(status){
      switch(status){
        case 'Pendient': return '#006898';
        default: return 'red';
      }
    }
  }
}
</script>
