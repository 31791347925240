<template>
  <div>
      <LoadingBar v-if="loading" />
      <div class="content" v-if="!loading">
          <h3 class="content-heading">Lista de servicios activos</h3>
          <base-block rounded>
            <b-table-simple>
                <b-thead class="table-dark">
                    <b-tr>
                        <b-th>Fecha</b-th>
                        <b-th>Hora</b-th>
                        <b-th>Tipo de servicio</b-th>
                        <b-th>Descripción</b-th>
                        <b-th># Pasajeros</b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="ser in services" :key="ser.Id">
                        <b-td>
                            {{ser.Date}}
                        </b-td>
                        <b-td>
                            {{ser.Hour}}
                        </b-td>
                        <b-td>
                            {{ser.TypeService}}
                        </b-td>
                        <b-td>
                            {{ser.Description}}
                        </b-td>
                        <b-td>
                            {{ser.Detail.length}}
                        </b-td>
                        <b-td align="right">
                            <b-button variant="warning" title="Editar">
                                <i class="fa fa-edit"></i>
                            </b-button>
                            <b-button variant="danger" title="Eliminar">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="services.length == 0">
                      <b-td colspan="6" class="text-center">Aún no tienes servicios activos</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
          </base-block>
      </div>
  </div>
</template>

<script>
import Tools from '../../scripts/tools'
import Urls from '../../scripts/urls'
import LoadingBar from '../loader/circle.vue'

export default {
    name: 'ListActive',
    title: 'Lista de servicios activos',
    components: {
        LoadingBar
    },
    data: () => ({
        services: [],
        loading: true,
    }),
    beforeMount(){
        let self = this
        Tools.VinderFetch({
            url: Urls.API.Service.List,
            data: {
              status: "Active"
            },
            recived: _dat => self.services = _dat,
            complete: () => self.loading = false
        })
    }
}
</script>