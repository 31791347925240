import Constants from "./constants"

window.onload = ()=>{
    if(localStorage.getItem('PEAR_TETMYS') != undefined && localStorage.getItem('PEAR_TETMYS') != "") {
        setInterval(()=>{
            console.log("Verificar TOKEN")
        }, 1000 * 60 * 5)
    }
}

const Session = {
    isValid: () => {
        let _token = localStorage.getItem('PEAR_TETMYS')
        if(_token != undefined && _token != ''){
            return true;
        }
        return false;
    },
    getToken: () => localStorage.getItem('PEAR_TETMYS'),
    getRole: () => atob(localStorage.getItem('IDENTITY')),
    getUrlHome: () => atob(localStorage.getItem('HOME')),
    getProfile: () => { return { Name: 'Firmenich' } },
    setSession: (model, _fun = d => console.log(d)) => {
        localStorage.setItem('PEAR_TETMYS', model.Token)
        localStorage.setItem('IDENTITY', btoa(model.TypeIdentity))
        let urlHome = "";
        switch(model.TypeIdentity){
            case Constants.Role.Super: urlHome = '/Root'; break
            case Constants.Role.Admin: urlHome = '/'; break
            case Constants.Role.Operator: urlHome = '/'; break
            case Constants.Role.Client: urlHome = '/'; break
            case Constants.Role.Enterprise: urlHome = '/'; break
        }
        localStorage.setItem('HOME', btoa(urlHome))
        _fun(urlHome);
    },
    clear: () => {
        localStorage.removeItem('PEAR_TETMYS')
        localStorage.removeItem('IDENTITY')
        localStorage.removeItem('HOME')
        localStorage.removeItem('REST')
        
        window.location = "/Login"
    }
};

export default Session;