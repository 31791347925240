<template>
  <!-- Page Content -->
  <div class="hero-static d-flex align-items-center">
    <div style="width: 90%; max-width: 600px;">
      <!-- Sign In Section -->
      <div class="bg-white" style="border-radius: 7px">
        <div class="content content-full">
          <b-row class="justify-content-center">
            <b-col md="12" class="py-4">
              <!-- Header -->
              <div class="text-center">
                <p class="mb-2">
                  <img src="/img/logo-hover.jpg" style="padding: 1em;" />
                </p>
                <h1 class="h4 mb-1">
                  Inicio de sesión
                </h1>
                <h2 class="h6 font-w400 text-muted mb-3">
                  Debes ingresar tus credenciales de acceso
                </h2>
              </div>
              <!-- END Header -->

              <!-- Sign In Form -->
              <b-form @submit.stop.prevent="onSubmit">
                <div class="py-3">
                  <div class="form-group">
                    <b-form-input size="lg" class="form-control-alt" id="username" name="username" placeholder="Usuario" v-model="form.username" :state="!$v.form.username.$error && null" aria-describedby="username-feedback"></b-form-input>
                  </div>
                  <div class="form-group">
                    <b-form-input type="password" size="lg" class="form-control-alt" id="password" name="password" placeholder="Contraseña" v-model="form.password" :state="!$v.form.password.$error && null" aria-describedby="password-feedback"></b-form-input>
                  </div>
                  <div class="form-group">
                    <div class="d-md-flex align-items-md-center justify-content-md-between">
                      <div class="py-2">
                        <router-link to="/Forgot" class="font-size-sm font-w500">Ólvide mi contraseña?</router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <b-row class="form-group row justify-content-center mb-0">
                  <b-col md="6" xl="5">
                    <b-button type="submit" variant="primary" block>
                      <i class="fa fa-fw fa-sign-in-alt mr-1"></i> Entrar
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
              <!-- END Sign In Form -->
            </b-col>
          </b-row>
        </div>
      </div>
      <!-- END Sign In Section -->

      <!-- Footer -->
      <div class="font-size-sm text-center text-muted py-3">
        <strong>{{ $store.getters.appName + ' ' + $store.getters.appVersion }}</strong> &copy; {{ $store.getters.appCopyright }}
      </div>
      <!-- END Footer -->
    </div>
  </div>
  <!-- END Page Content -->
</template>

<script>
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Session from '../../scripts/Session'
import Tools from '../../scripts/tools'
import Urls from '../../scripts/urls'

export default {
  mixins: [validationMixin],
  title: "Login",
  data () {
    return {
      form: {
        username: null,
        password: null
      }
    }
  },
  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3)
      },
      password: {
        required,
        minLength: minLength(5)
      }
    }
  },
  methods: {
    onSubmit () {
      let self = this
      self.$v.form.$touch()

      if (self.$v.form.$anyError) {
        return
      }

      Tools.VinderFetch({
        url: Urls.API.Auth.Login,
        data: {
          Mail: self.form.username,
          Password: Tools.EncryptPass(self.form.password),
          VersionId: 1
        },
        success: (res) => {
          Session.setSession(res.DsResponse.Data, url => window.location = url)
        }
      });
    }
  }
}
</script>
