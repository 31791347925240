import Vue from 'vue'
import VueRouter from 'vue-router'
//import Session from '../scripts/Session';

import Home from '../components/home/IndexComponent.vue'
import AccountComponent from '../components/Account/AccountComponent.vue';
import Constants from '../scripts/constants';
import ForgotPasswordComponent from '../components/Account/ForgotPasswordComponent'

import Root from '../components/Root/RootComponent.vue'
import RootService from '../components/Root/RootServiceComponent.vue'
import RootUser from '../components/Root/RootUserComponent.vue'

import AddServiceComponent from '../components/service/AddServiceComponent'
import ActiveServiceComponent from '../components/service/ActiveServiceComponent'
import SendServiceComponent from '../components/service/SendServiceComponent'
import AcceptServiceComponent from '../components/service/AcceptServiceComponent'
import Session from '../scripts/Session';

import ListEmployeeComponent from '../components/employee/ListEmployeeComponent.vue'

Vue.use(VueRouter)

const routes = [
  // Home
  { path: '/', name: 'Home', component: Home, meta: { role: Constants.Role.Enterprise }},
  { path: '/Root', name: 'Root', component: Root, meta: { role: Constants.Role.Super }},
  { path: '/Root/Service', name: 'RootService', component: RootService, meta: { role: Constants.Role.Super }},
  { path: '/Root/User', name: 'RootUser', component: RootUser, meta: { role: Constants.Role.Super }},
  // Account
  { path: '/Login', name: 'Login', component: AccountComponent, meta: { role: Constants.Role.Allow } },
  { path: '/Forgot', name:'Forgot', component: ForgotPasswordComponent, meta: { role: Constants.Role.Allow} },
  // Services
  { path: '/Service/Add', name: 'addService', component: AddServiceComponent, meta: { role: Constants.Role.Enterprise } },
  { path: '/Service/Active', name: 'activeService', component: ActiveServiceComponent, meta: { role: Constants.Role.Enterprise } },
  { path: '/Service/Send', name: 'SendService', component: SendServiceComponent, meta: { role: Constants.Role.Enterprise } },
  { path: '/Service/Accept', name: 'AcceptService', component: AcceptServiceComponent, meta: { role: Constants.Role.Enterprise } },
  // Config Users
  { path: '/Config/Employee', name: "configEmployee", component: ListEmployeeComponent, meta: { role: Constants.Role.Enterprise } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
 
router.beforeEach((to, from, next) => {

  if (!Session.isValid() && to.name != "Login" && to.name != "Forgot") {
    window.location = '/Login'
    return false;
  }
  
  let tokenRole = Session.getRole()
  let roleRoute = to.matched[0]?.meta.role

  if(roleRoute == null){
    window.location = '/Login'
    return false;
  }
  
  if(roleRoute == Constants.Role.Allow){
    next()
  }
  else if(tokenRole != roleRoute){
    window.location = Session.getUrlHome()
  } else {
    next()
  }
})

export default router
