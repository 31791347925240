<template>
  <!-- Page Content -->
  <div class="hero-static d-flex align-items-center">
    <div style="width: 90%; max-width: 600px;">
      <!-- Sign In Section -->
      <div class="content content-full">
          <base-block rounded themed header-class="bg-primary-dark" class="mb-0" title="Recuperación de contraseña">
            <template #options>
              <router-link to="/login" class="btn-block-option" v-b-tooltip.hover.nofade.left="'Iniciar Sesion'">
                <i class="fa fa-sign-in-alt"></i>
              </router-link>
            </template>
            <div class="p-sm-3 px-lg-4 py-lg-5 text-center">
              <p class="mb-2">
                  <img src="/img/logo-hover.jpg" style="padding: 1em;" />
                </p>
              <h1 class="h4 mb-1">Recupera tu contraseña</h1>
              <p class="text-muted text-left">
                Por favor ingresa tu correo para continuar. Si tu correo es valido te llegará un link con los siguientes pasos para recuperar tu contraseña de acceso.
              </p>

              <!-- Reminder Form -->
              <b-form @submit.stop.prevent="onSubmit">
                <div class="form-group py-3">
                  <b-form-input size="lg" class="form-control-alt" id="reminder" name="reminder" placeholder="Correo electrónico" v-model="$v.form.reminder.$model" :state="!$v.form.reminder.$error && null" aria-describedby="reminder-feedback"></b-form-input>
                  <b-form-invalid-feedback id="reminder-feedback">
                   Por favor Ingresa tu correo electrónico
                  </b-form-invalid-feedback>
                </div>
                <b-row class="form-group">
                  <b-col class="text-center">
                    <router-link to="/Login" class="btn btn-alt-primary">
                    <i class="fa fa-fw fa-envelope mr-1"></i> Enviar correo
                    </router-link>
                  </b-col>
                </b-row>
              </b-form>
              <!-- END Reminder Form -->
            </div>
          </base-block>
        </div>
      <!-- END Sign In Section -->

      <!-- Footer -->
      <div class="font-size-sm text-center text-muted py-3">
        <strong>{{ $store.getters.appName + ' ' + $store.getters.appVersion }}</strong> &copy; {{ $store.getters.appCopyright }}
      </div>
      <!-- END Footer -->
    </div>
  </div>
  <!-- END Page Content -->
</template>


<script>
// Vuelidate, for more info and examples you can check out https://github.com/vuelidate/vuelidate
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Tools from '../../scripts/tools'

export default {
  mixins: [validationMixin],
  title: "Recuperación",
  data () {
    return {
      form: {
        reminder: null
      }
    }
  },
  validations: {
    form: {
      reminder: {
        required,
        minLength: minLength(3)
      }
    }
  },
  
  methods: {
    created(){
      
    },
    onSubmit () {
      this.isLoadign = true;
      this.$v.form.$touch()

      if (this.$v.form.$anyError) {
        this.isLoadign = false;
        return
      }
      const dat = {
        Email: this.$data.form.reminder
      };      
      Tools.VinderFetch({
        url: "",
        data: dat
      });
    }
  }
}
</script>
