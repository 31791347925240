<template>
  <div>
      <LoadingBar v-if="loading" label="Agregando Empleado" :minSpinner="false"/>
        <div class="content">
            <b-button size="sm" title="Regresar" style="float: right" v-on:click="$emit('exit')">
                <i class="fa fa-fw fa-arrow-left"></i> Regresar
            </b-button>
        </div>
        <div class="content">
            <h2 class="content-heading">
                Agregar <small>Empleado</small>
            </h2>
            <base-block rounded>
                <b-row>
                    <b-col sm="12" md="6">
                        <b-form-group label="Nombre">
                            <b-form-input class="form-control" v-model="form.Name" placeholder="Nombre"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Teléfono">
                            <b-form-input class="form-control" v-model="form.Phone" placeholder="7225644122"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="4">
                        <b-form-group label="Calle y número">
                            <b-form-input class="form-control" v-model="form.Street" placeholder="Del Calvario 255"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Colonía, Municipio y estado">
                            <b-form-input class="form-control" v-model="form.Town" placeholder="Colonia Centro, Mexico, Mexico"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Código Postal">
                            <b-form-input class="form-control" v-model="form.Code" placeholder="51200"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12">
                        <b-form-group label="Dirección">
                            <b-form-input id="txtDireccion" v-on:keypress="(e)=>{e.preventDefault()}" v-on:keydown="(e)=>{e.preventDefault()}" class="form-control" placeholder="Calle y número, colonia ..." :value="form.Street + ', ' + form.Town + ', ' + form.Code"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6">
                        <b-form-group label="Correo electrónico">
                            <b-form-input class="form-control" v-model="form.Email" placeholder="correo@duvatec.com"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6">
                        <b-form-group label="Código de seguridad">
                            <b-form-input class="form-control" v-model="form.NumEmployee"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button size="sm" title="Guardar" class="btn-success" v-on:click="SaveUser">
                            <i class="fa fa-fw fa-save"></i> Guardar
                        </b-button> 
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <br/>
                    </b-col>
                </b-row>
            </base-block>
        </div>
  </div>
</template>

<script>
import Constants from '../../scripts/constants'
import Tools from '../../scripts/tools'
import LoadingBar from '../../components/loader/circle';
import Urls from '../../scripts/urls'

export default {
    name: "AddEmployee",
    title: "Agregar nuevo empleado",
    components: {
        LoadingBar
    },
    props: {
        isSubmodule: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        SaveUser(){
            let def = this
            if(def.validate()){
                def.loading = true
                def.form.Password = Tools.EncryptPass(def.form.Password)
                Tools.VinderFetch({
                    url: Urls.API.Employee.AddEmployee,
                    data: def.form,
                    success: (res) => {
                        if(res.IsOk){
                            Tools.toastSuccess("¡OK!", res.Message)
                            def.$emit("exit", true)
                        } else {
                            def.form.Password = ""
                        }
                        def.loading = false
                    },
                    error: () => def.loading = false
                })
            }
        },
        validate(){
            if(this.form.Name.trim() == "") { Tools.swalWarning("¡Cuidado!", "El nombre es obligatorio"); return false }
            if(this.form.Phone.trim() == "") { Tools.swalWarning("¡Cuidado!", "El teléfono es obligatorio"); return false }
            if(this.form.Phone.trim().length < 10) { Tools.swalWarning("¡Cuidado!", "El número tiene que tener 10 digitos"); return false }
            if(this.form.Code == 0) { Tools.swalWarning("¡Cuidado!", "El código postal es obligatorio"); return false }
            if(this.form.Email.trim() == "") { Tools.swalWarning("¡Cuidado!", "El correo electrónico es obligatorio"); return false }
            this.form.Address = document.getElementById("txtDireccion").value;
            return true
        }
    },
    data(){
        return {
            loading: false,
            roles: [ 
                {
                    name: Constants.Role.Enterprise,
                    value: Constants.Role.Enterprise
                }
            ],
            TypeUser: Constants.Role.Enterprise,
            form: {
                Email: "",
                NumEmployee: Math.floor((Math.random() * (9999 - 1000 + 1)) + 1000),
                Town: "Colonia",
                Street: "Calle",
                Name: "",
                Address: "",
                Code: 51200,
                Phone: ""
            }
        }
    }
}
</script>

<style>

</style>