<template>
  <ul :class="classContainer">
    <li
      v-for="(node, index) in nodes"
      :key="`node-${index}`"
      :class="{
        'nav-main-heading': node.heading,
        'nav-main-item': !node.heading,
        'open': node.sub && node.subActivePaths ? subIsActive(node.subActivePaths) : false
      }"
    >
      {{ node.heading ? node.name : '' }}
      <router-link
        v-if="!node.heading"
        :to="node.to || '#'"
        class="nav-main-link"
        :class="node.sub ? 'nav-main-link-submenu' : ''"
        :active-class="(node.to && node.to == pathName) ? 'active' : ''"
        :event="node.sub ? '' : 'click'"
        @click.native="linkClicked($event, node.to)"
      >
        <simple-line-icons v-if="node.icon" :icon="node.icon"  size="small" no-svg />
        <span v-if="node.name" class="nav-main-link-name" style="margin-left:10px">{{ node.name }}</span>
        <span v-if="node.badge" class="nav-main-link-badge badge badge-pill badge-primary"
            :class="node['badge-variant'] ? `badge-${node['badge-variant']}` : 'badge-primary' "
        >{{ node.badge }}</span>
      </router-link>
      <base-navigation v-if="node.sub" :nodes="node.sub" sub-menu></base-navigation>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BaseNavigation',
  props: {
    nodes: {
      type: Array,
      description: 'The nodes of the navigation'
    },
    subMenu: {
      type: Boolean,
      default: false,
      description: 'If true, a submenu will be rendered'
    },
    dark: {
      type: Boolean,
      default: false,
      description: 'Dark mode for menu'
    },
    horizontal: {
      type: Boolean,
      default: false,
      description: 'Horizontal menu in large screen width'
    },
    horizontalHover: {
      type: Boolean,
      default: false,
      description: 'Hover mode for horizontal menu'
    },
    horizontalCenter: {
      type: Boolean,
      default: false,
      description: 'Center mode for horizontal menu'
    },
    horizontalJustify: {
      type: Boolean,
      default: false,
      description: 'Justify mode for horizontal menu'
    }
  },
  computed: {
    classContainer () {
      return {
        'nav-main': !this.subMenu,
        'nav-main-submenu': this.subMenu,
        'nav-main-dark': this.dark,
        'nav-main-horizontal': this.horizontal,
        'nav-main-hover': this.horizontalHover,
        'nav-main-horizontal-center': this.horizontalCenter,
        'nav-main-horizontal-justify': this.horizontalJustify
      }
    }
  },
  data(){
    return {
      pathName: window.location.pathname
    }
  },
  methods: {
    subIsActive (paths) {
      const activePaths = Array.isArray(paths) ? paths : [paths]

      return activePaths.some(path => {
        this.pathName = path;
        return this.$route.path.indexOf(path) === 0 // current path starts with this path string
      })
    },
    linkClicked (e, path) {
      this.pathName = path;
      // Get window width
      let windowW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

      if (windowW < 992) {
        this.$store.commit('sidebar', { mode: 'close' })
      }
    }
  }
}
</script>
