<template>
  <div>
    <svg class="loading-spinner" v-if="minSpinner">
      <circle
        :cx="circlePositions[index] && circlePositions[index].x"
        :cy="circlePositions[index] && circlePositions[index].y"
        :r="item.radius"
        :fill="item.color"
        v-for="(item, index) in circles"
        :key="index"/>
    </svg>
    <div class="Loading-head" v-if="!minSpinner">
      <svg class="loading-spinner">
        <circle
          :cx="circlePositions[index] && circlePositions[index].x"
          :cy="circlePositions[index] && circlePositions[index].y"
          :r="item.radius"
          :fill="item.color"
          v-for="(item, index) in circles"
          :key="index"/>
      </svg>
      <h3>{{printLabel.text}}</h3>
    </div>
  </div>
</template>

<script>
const CENTER_X = 50;
const CENTER_Y = 50;
const RADIUS = 20;

function positionOnCircle(radius, angle, center) {
  return {
    x: center.x + (radius * Math.cos(toRadians(angle))),
    y: center.y + (radius * Math.sin(toRadians(angle)))
  }
}

function toRadians(angle) {
  return angle * Math.PI / 180;
}

function calculatePositions(component) {
  let angleIncrement = 360 / component.circles.length;
  let positions = {};
  component.circles.forEach((circle, index) => {
    positions[index] = positionOnCircle(
      RADIUS,
      angleIncrement * index,
      {x: CENTER_X, y: CENTER_Y}
    )
  })
  return positions;
}

export default {
  props:{
    minSpinner: {
      type: Boolean,
      default: true,
      require: true
    },
    label: {
      type: String,
      default: '...',
      require: true
    }
  },
  data() {
    return {
      circles: [
        {color: '#E0F2F1', radius: 0},
        {color: '#B2DFDB', radius: 0},
        {color: '#80CBC4', radius: 0},
        {color: '#4DB6AC', radius: 0},
        {color: '#26A69A', radius: 0},
        {color: '#00897B', radius: 0},
        {color: '#00796B', radius: 0},
        {color: '#00695C', radius: 0},
        {color: '#004D40', radius: 0},
      ],
      counter: 0,
      interval: null,
      printLabel:{
        interVal: null,
        text: "",
        count: 0
      }
    }
  },
  computed: {
    circlePositions: calculatePositions
  },
  created() {
    let self = this
    self.interval = setInterval(() => {
      self.counter++;
      self.circles = self.circles.map((item, index) => ({
        ...item,
        radius: (self.counter + index) % 8
      }))
    }, 70)
    if(self.minSpinner == false && self.label != undefined && self.label != null && self.label.length > 0) {
      self.printLabel.interval = setInterval( () => {
        self.printLabel.text += self.label[self.printLabel.count]
        if(self.printLabel.count == self.label.length){
          self.printLabel.count = 0;
          self.printLabel.text = ""
        } else {
          self.printLabel.count++
        }
      }, 200)
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
}
</script>

<style scoped>
.loading-spinner {
  width: 100px;
  height: 100px;
}
.Loading-head{
  position: fixed;
  top: 0px;
  left:0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.7);
  text-align: center !important;
  z-index: 9999;
}

.Loading-head h3{
  color: white;
}

.Loading-head .loading-spinner{
  margin-top: 40vh;
}
</style>
