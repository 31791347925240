<template>
  <div>
      <div class="content text-right" v-if="false">
          <b-button variant="primary" v-on:click="addUser = true">
              <i class="fa fa-plus"></i> Agregar usuario
          </b-button>
      </div>
      <div class="content" v-if="!addUser">
        <base-block rounded>
            <label class="content-heading">
                Listado de usuarios
            </label>
            <b-row>
                <b-col md="12">
                    <b-table-simple responsive table-class="table-vcenter">
                        <b-thead>
                            <b-tr>
                                <b-th>Nombre</b-th>
                                <b-th>Correo</b-th>
                                <b-th>Activo</b-th>
                                <b-th class="text-center">...</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="user in users" :key="user.Id">
                                <b-td class="text-left">
                                    {{ user.Name }}
                                </b-td>
                                <b-td class="font-w600 font-size-sm">
                                    {{ user.Email }}
                                </b-td>
                                <b-td class="font-w600 font-size-sm">
                                    {{ user.Active == 1? 'Activo' : 'Inactivo' }}
                                </b-td>
                                <b-td class="text-right">
                                    
                                </b-td>
                            </b-tr>
                            <b-tr v-if="users.length == 0">
                                <b-td colspan="4" class="text-center">Sin resultados</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
        </base-block>
      </div>
  </div>
</template>

<script>
import Tools from '../../scripts/tools'
import Urls from '../../scripts/urls';
export default {
    title: "Lista de usuarios",
    data(){
        return {
            users:[],
            addUser: false
        }
    },
    beforeMount(){
        let self = this
        Tools.VinderFetch({
            url: Urls.API.Root.ListUser,
            recived: data => self.users = data 
        });
    }
}
</script>