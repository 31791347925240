<template>
    <div>
        <LoadingBar v-if="loading" label="Solicitando servicio" :minSpinner="false" />
        <div class="content">
            <base-block rounded :title="titleService">
            <p class="font-size-md text-muted">
                Por favor capture los datos del servicio. Los campos requeridos se marcan con un <strong style="color:red">*</strong>
            </p>
            <b-row>
            <b-col lg="12">
                <b-form @submit.prevent class="formData">
                    <b-row>
                        <b-col :md="(serviceNew.typeDeliveryId != Constants.Delivery.TransportFile) ? 6 : 12">
                            <label><strong style="color:red">*</strong>Tipo de envío</label>
                            <b-select v-model="serviceNew.TypeDeliveryId" @change="ChangeType">
                                <option v-for="opt in opts" :key="opt.Id" :value="opt.Id">{{opt.Name}}</option>
                            </b-select>
                        </b-col>
                        <b-col md="6" v-if="(serviceNew.TypeDeliveryId != Constants.Delivery.TransportFile)">
                            <label>Número de pasajeros</label>
                            <b-select v-model="numberEmployee">
                                <option value="1" selected>1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </b-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="6">
                            <label><strong style="color:red">*</strong> Fecha</label>
                            <b-form-datepicker v-model="serviceNew.Date" locale="es"></b-form-datepicker>
                        </b-col>
                        <b-col md="6">
                            <label><strong style="color:red">*</strong> Hora</label>
                            <b-form-timepicker v-model="serviceNew.Time" locale="es"></b-form-timepicker>
                        </b-col>
                    </b-row>
                    <b-row v-if="serviceNew.typeDeliveryId != Constants.Delivery.TransportFile && numberEmployee == 1">
                        <b-col md="6">
                            <label style="display: block">
                                <strong style="color:red">*</strong> Origen
                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="AddPlace">Nuevo</span>
                            </label>
                            <v-select v-model="serviceNew.Detail[0].Origin" label="Name" placeholder="Seleccionar Origen" :options="places" class="vue-select2">
                                <div slot="no-options">Sin lugares</div>
                            </v-select>
                        </b-col>
                        <b-col md="6">
                            <label style="display: block">
                                <strong style="color:red">*</strong> Destino
                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="AddPlace">Nuevo</span>
                            </label>
                            <v-select v-model="serviceNew.Detail[0].Destiny" label="Name" placeholder="Seleccionar Destino" :options="places" class="vue-select2">
                                <div slot="no-options">Sin lugares</div>
                            </v-select>
                        </b-col>
                        <b-col v-if="(serviceNew.TypeDeliveryId != Constants.Delivery.TransportFile)">
                            <label style="display: block">
                                Pasajero
                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="$router.push('/Config/Employee')">Nuevo</span>
                            </label>
                            <v-select v-model="serviceNew.Detail[0].Employee" :options="employees" class="vue-select2" placeholder="Empleado" label="Name"></v-select>
                        </b-col>
                    </b-row>
                    <b-row>
                    <b-col v-if="numberEmployee > 1 && (serviceNew.TypeDeliveryId != Constants.Delivery.TransportFile)">
                        <div class="content" style="padding: 0px 50px">
                                <b-card v-for="(number, index) in parseInt(numberEmployee)" :key="number" 
                                    style="border: solid .1em #f0f0f0; padding: 10px; border-radius: 10px;margin-bottom: 20px">
                                    <label>Pasajero {{number}}</label>
                                    <b-row>
                                        <b-col sm="6">
                                            <label style="display: block">
                                                <strong style="color:red">*</strong> Empleado
                                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="$router.push('/Config/Employee')">Nuevo</span>
                                            </label>
                                            <v-select 
                                                v-model="serviceNew.Detail[index].Employee"
                                                :options="employees" label="Name" class="vue-select2" 
                                                placeholder="Empleado"></v-select>
                                        </b-col>
                                        <b-col sm="6">
                                            <label style="display: block">Comentarios</label>
                                            <b-input 
                                                v-model="serviceNew.Detail[index].Comments"
                                                placeholder="Comentarios"></b-input>
                                        </b-col>
                                        <b-col sm="6">
                                            <label style="display: block">
                                                <strong style="color:red">*</strong> Origen
                                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="AddPlace">Nuevo</span>
                                            </label>
                                            <v-select
                                                v-model="serviceNew.Detail[index].Origin"
                                                label="Name" placeholder="Seleccionar Origen" 
                                                :options="places" class="vue-select2">
                                            <div slot="no-options">Sin lugares</div>
                                        </v-select>
                                        </b-col>
                                        <b-col sm="6">
                                            <label style="display: block">
                                                <strong style="color:red">*</strong> Destino
                                                <span style="font-size: .7em; color: blue; float: right; cursor: pointer;" v-on:click="AddPlace">Nuevo</span>
                                            </label>
                                            <v-select
                                                v-model="serviceNew.Detail[index].Destiny"
                                                label="Name" 
                                                placeholder="Seleccionar Destino" 
                                                :options="places" class="vue-select2">
                                                <div slot="no-options">Sin lugares</div>
                                            </v-select>
                                        </b-col>
                                    </b-row>
                                </b-card>
                        </div>
                    </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                            <div class="form-group">
                                <label><strong style="color:red">*</strong> Descripción del servicio</label>
                                <b-textarea v-model="serviceNew.Description" placeholder="Se va a recoger ..."></b-textarea>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12" class="text-right">
                            <b-button variant="alt-success" @click="SendService">Solicitar servicio</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <br/>
                        </b-col>
                    </b-row>
                </b-form>
            </b-col>
            </b-row>
        </base-block>
        </div>
    </div>
</template>

<script>
import Tools from '../../scripts/tools'
import Urls from '../../scripts/urls'
import LoadingBar from '../../components/loader/circle';
import VueSelect from 'vue-select'
import Constants from '../../scripts/constants';

export default {
    name: "service-layout",
    components: {
        LoadingBar,
        'v-select': VueSelect
    },
    props:{
        titleService: {
            type: String,
            require: false,
            default: 'Servicio'
        },
        service: {
            require: false
        },
        isNew:{
            type: Boolean,
            require: false,
            default: false
        }
    },
    watch:{
        numberEmployee(){
            this.serviceNew.Detail = []
            for(let i = 0; i< this.numberEmployee; i++){
                this.serviceNew.Detail.push({
                    i: {
                        Employee: null,
                        Origin: null,
                        Destiny: null,
                        Comments: ""
                    }
                })
            }
        }
    },
    data(){
        return {
            loading: false,
            Constants: Constants,
            numberEmployee: 1,
            serviceNew:{
                Id: 0,
                TicketNumber:   0,
                Time: '',
                Date: '',
                DateService: '',
                Description: '',
                Status: 'Pendient',
                TypeDeliveryId: 1,
                Detail: [{
                    0: {
                        Employee: null,
                        Origin: null,
                        Destiny: null,
                        Comments: ""
                    }
                }]
            },
            opts: [],
            places: [],
            employees: [],
            origin: null
        }
    },
    created(){
        let self = this
        let today = new Date()
        let h = today.getHours()
        let m = today.getMinutes()
        self.serviceNew.Time = h + ":" + m
        self.serviceNew.Date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() 
    },
    beforeMount(){
        let self = this
        Tools.VinderFetch({
            url: Urls.API.Catalog.TypeDelivery,
            recived: dat => self.opts = dat
        })
        self.ListEmployee()
        this.ListPlaces()
    },
    methods: {
        ListPlaces(){
            let self = this
            Tools.VinderFetch({
                url: Urls.API.Service.ListPlace,
                recived: _data => self.places = _data
            })
        },
        SendService(){
            let self = this
            self.loading = true
            self.serviceNew.DateService = self.serviceNew.Date + " " + this.serviceNew.Time
            Tools.VinderFetch({
                url: Urls.API.Service.Add,
                data: self.serviceNew,
                success: res => {
                    if(res.IsOk) {
                        Tools.swalSuccess("OK", "El servicio fue solicitado");
                        self.$router.push("/Service/Send")
                    }
                },
                complete: () => self.loading = false
            })
        },
        ChangeType(){
            if(this.serviceNew.TypeDeliveryId == Constants.Delivery.TransportFile){
                this.numberEmployee = 1;
            }
        }, 
        ListEmployee(){
            let self = this
            Tools.VinderFetch({
                url: Urls.API.Employee.ListEmployee,
                recived: _data => self.employees = _data
            })
        },
        AddPlace(){
            let self = this
            self.$prompt("Ingresa la dirección")
            .then(text => {
                if(text != undefined && text != ""){
                    Tools.VinderFetch({
                        url: Urls.API.Service.AddPlace,
                        data: {
                            Name: text
                        },
                        recived: _dat => self.places.push(_dat)
                    })
                }
            });
        }
    }
}
</script>

<style lang="scss">
    @import '~vue-select/src/scss/vue-select';
    .formData > div.row > div {
        margin-bottom: 12px;
    }
</style>