const IsProd = true
const Sites = {
    API: IsProd ? "https://api.control-tetmys.com/api/v1/" : "https://localhost:44398/api/v1/"
}
const Urls = {
    IsProd: false,
    API: {
      Auth: {
          Login:    Sites.API + "Auth/Login",
          Register: Sites.API + "Auth/Register",
          Forgot:   Sites.API + "Auth/ForgotPassword"
      },
      Root: {
          ListUser: Sites.API + "User/List",
          AddUser:  Sites.API + "User/AddUser"
      },
      Employee: { 
        ListEmployee:   Sites.API + "Employee/ListEmployee",
        AddEmployee:    Sites.API + "Employee/AddEmployee"
      },
      Catalog: {
        TypeDelivery: Sites.API + "Catalog/TypeDelivery"
      },
      Service: {
        List:       Sites.API + "Service/ListService",
        ListPlace:  Sites.API + "Service/ListPlace",
        AddPlace:   Sites.API + "Service/AddPlace",
        Add:        Sites.API + "Service/AddService"
      }
    },
    Sites: Sites
}

export default Urls