import Vue from 'vue'
import App from './App.vue'
import Login from './layout/external.vue'
import router from './router'
import Session from './scripts/Session';

// Bootstrap Vue
import BootstrapVue from 'bootstrap-vue'
import store from './store'

import BaseLayoutModifier from '../src/components/BaseLayoutModifier'
import BaseBlock from '../src/components/BaseBlock'
import BaseBackground from '../src/components/BaseBackground'
import BasePageHeading from '../src/components/BasePageHeading'
import BaseNavigation from '../src/components/BaseNavigation'
import SimpleLineIcons from 'vue-simple-line'

import titleMixin from '../src/scripts/titlemixin'
Vue.mixin(titleMixin)

import VueSweetalert2 from 'vue-sweetalert2'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
Vue.use(CoolLightBox)

import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

Vue.use(VueSweetalert2, {
  buttonsStyling: false,
  customClass: {
    confirmButton: 'btn btn-success m-1',
    cancelButton: 'btn btn-danger m-1',
    input: 'form-control'
  }
})

// Register global plugins
Vue.use(BootstrapVue)

Vue.component(BaseLayoutModifier.name, BaseLayoutModifier)
Vue.component(BaseBlock.name, BaseBlock)
Vue.component(BaseBackground.name, BaseBackground)
Vue.component(BasePageHeading.name, BasePageHeading)
Vue.component(BaseNavigation.name, BaseNavigation)

Vue.component('simple-line-icons', SimpleLineIcons)

Vue.config.productionTip = false


new Vue({
  store,
  router,
  render: h => Session.isValid() ? h(App) : h(Login)
}).$mount('#app')
