const Constants = {
    Role: {
        Super: 'Super',
        Admin: 'Admin',
        Operator: 'Operator',
        Client: 'Client',
        Enterprise: 'Enterprise',
        Allow: 'Allow'
    },
    Delivery: {
        InPersonal: 1,
        OutPersonal: 5,
        TransportEjecutive: 4,
        TransportAirport: 3,
        TransportFile: 2
    }
}


export default Constants;