import Session from "./Session"
import Vue from 'vue'



const Tools = {
    extend(a, b) {
        for(var key in b)
            if(Object.prototype.hasOwnProperty.call(b,key))
                a[key] = b[key]
        return a
    },    
    VinderFetch(opts) {
        const defs = {
            url: "",
            method: "POST",
            data: {},
            success: () => {},
            error: () => {},
            recived: () => {},
            complete: () => {}
        }
        const conf = Tools.extend(defs, opts)
        //conf.url = Tools.API + conf.url
        const headConfig = {
            "Content-type":  "application/json; charset=UTF-8",
            'Authorization': '', 
        };

        if(Session.isValid()){
            headConfig.Authorization = 'Bearer ' + Session.getToken()
        }
    
        fetch(conf.url, {
            method: conf.method,
            body: JSON.stringify(conf.data),
            headers: headConfig
        })
        .then(response => {
            conf.complete();
            return response.json();
        })  // Convert to json
        .then(json => {
            if(json.Message === 'El token ha caducado'){
                Session.clear()
            } else if(!json.IsOk) {
                Tools.swalError("¡Oh no!", json.Message)
            } else {
                if(json.DsResponse != null) {
                    conf.recived(json.DsResponse.Data)
                } else {
                    conf.recived([])
                }
            }
            conf.success(json);
        })   // Print data to console
        .catch(err => {
            Tools.swalError("¡Oh no!", "Tuvimos un problema, pronto lo resolveremos.")
            conf.error(err)
        })      // Catch errors
    },
    EncryptPass(pass, num = 0) {
        if(num == 0)
            num = Math.round(Math.random() * (9 - 2) + 2);

        let c = 0, sPass = pass
        do{ sPass = btoa(sPass); c++ }while(c < num);
        switch(num){
            case 1: sPass += String.fromCharCode(117); break 
            case 2: sPass += String.fromCharCode(114); break
            case 3: sPass += String.fromCharCode(99); break
            case 4: sPass += String.fromCharCode(105); break
            case 5: sPass += String.fromCharCode(101); break
            case 6: sPass += String.fromCharCode(108); break
            case 7: sPass += String.fromCharCode(97); break
            case 8: sPass += String.fromCharCode(103); break
            case 9: sPass += String.fromCharCode(111); break
        }
        return sPass
    },
    GetUUID() {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        )
    },
    Toast(title, content, variant = null, append = false, toaster = 'b-toaster-top-right', autoHideDelay = 5000) {
        const vm = new Vue()
        vm.$bvToast.toast(content, {
            title: title,
            toaster: toaster,
            variant: variant,
            autoHideDelay: autoHideDelay,
            appendToast: append
        })
    },
    notificationOk(description){
        Tools.toastSuccess("¡OK!", description)
    },
    toastSuccess(title = "¡OK!", description = "Movimiento exitoso"){
        const vm = new Vue()
        vm.$bvToast.toast(description, {
            title: title,
            toaster: 'b-toaster-top-right',
            variant: 'success',
            autoHideDelay: 3000,
            appendToast: false
        })
    },
    swalSuccess (title = "OK", description = "Movimiento exitoso", _fun = ()=>{}) {
        const vm = new Vue()
        vm.$swal(title, description, 'success').then(_fun)
    },
    swalError (title = "Error", description = "Lo sentimos") {
        const vm = new Vue()
        vm.$swal(title, description, 'error')
    },
    swalWarning (title = "Cuidado", description = "Lo sentimos") {
        const vm = new Vue()
        vm.$swal(title, description, 'warning')
    },
    alertWarning(description = "Lo sentimos") {
        const vm = new Vue()
        vm.$swal('¡Cuidado!', description, 'warning')
    },
    randomColor (){
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    randomColorPastel() {
        let hue = Math.floor(Math.random() * 360);
        const pastel = 'hsl(' + hue + ', 100%, 80%)';
        return pastel;
    }
}

export default Tools